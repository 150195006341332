.CreatePost {
  display: flex;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 20px;
  gap: 20px;
  margin-top: 20px;
  background-color: rgb(236, 219, 214);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

  .left-part-create-post {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: content;
  }

  .right-part-create-post {
    flex-grow: 1;

    .captionInput {
      padding: 20px;
      border: none;
      background-color: rgb(152, 196, 197);
      border-radius: 8px;
      font-size: 1.4rem;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
      font-family: Verdana, Geneva, Tahoma, sans-serif;
    }

    .captionInput::placeholder {
      font-size: 1rem;
    }



    input {
      width: 100%;
    }

    .img-container {
      margin-top: 20px;

      .post-img {
        width: 100%;
        aspect-ratio: 16/9;
      }
    }

    .labelImg {
      display: inline-block;
      background-color: var(--accent-color);
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      cursor: pointer;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    }

    .inputImg {
      display: none;
    }

    .bottom-part {
      display: flex;
      margin-top: 20px;
      justify-content: space-between;
      align-items: center;
    }
  }
}