.Profile {
  .container {
    display: flex;
    gap: 20px;

    @media only screen and (max-width: 768px) {
      display: block;
      position: relative;
    }

    .left-part {
      flex: 2;

      @media only screen and (max-width: 768px) {
        position: absolute;
        top: 360px;
        margin: 10px;
      }
    }

    .right-part {
      flex: 1;
      margin-top: 20px;
      max-height: 400px;
      background-color: rgb(236, 219, 214);
      border-radius: 4px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

      @media only screen and (max-width: 768px) {
        position: absolute;
        top: 0;
        width: 95%;
        margin: 10px;
      }

      .profile-card {
        border: 1px solid var(--border-color);
        border-radius: 4px;
        text-align: center;
        padding: 40px;


        .user-img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
        }

        .user-name,
        .follower-info {
          margin-top: 12px;
        }

        .follower-info {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 40px;
        }

        .follow,
        .update-profile {
          margin-top: 20px;
          display: inline-block;
        }
      }
    }
  }
}