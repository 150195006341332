* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to bottom right, rgb(233, 150, 150), rgb(209, 209, 187));
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --border-color: #ddd;
  --accent-color: #4f8eff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #212121;
}

h3,
h6 {
  font-weight: 400;
}
p {
  color: #555;
  font-size: 0.9rem;
}

.hover-link {
  cursor: pointer;
}

.hover-link:active {
  color: red;
}

.container {
  max-width: 960px;
  margin-inline: auto;
}

.btn-primary {
  background-color: var(--accent-color);
  color: white;
  font-weight: bold;
  padding: 10px 40px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.btn-primary:hover {
  background-color: #91b0eb;
}
.btn-primary:active {
  background-color: var(--accent-color);
}

.btn-secondary {
  border: 2px solid var(--accent-color);
  color: var(--accent-color);
  font-weight: bold;
  padding: 10px 40px;
  border-radius: 4px;
  cursor: pointer;
  background: none;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.btn-secondary:hover {
  background-color: #ddd;
}
.btn-secondary:active {
  background: none;
}

.input-box {
  padding: 8px 16px;
  background-color: transparent;
  color: #555;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.input-box:focus {
  outline: none;
}
.input-box::placeholder {
  color: rgb(190, 209, 209);
}
