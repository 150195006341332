.Post {
    border: 1px solid var(--border-color);
    margin-top: 20px;
    border-radius: 4px;
    background-color: rgb(236, 219, 214);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

    .heading {
        display: flex;
        align-items: center;
        gap: 20px;
        height: 60px;
        padding: 20px;
        cursor: pointer;
    }

    .content {
        height: 400px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .footer {
        padding: 20px;

        .like {
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;

            .icon {
                font-size: 1.5rem;
            }
        }

        .caption {
            margin-top: 12px;
        }

        .time-ago {
            margin-top: 12px;
            color: #aaa;
        }
    }
}