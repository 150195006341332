.Avatar{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    img{
        height: 100%;
        width: 100%;
        border-radius: 50%;
        object-fit: cover;
    }
}