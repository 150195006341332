.Follower {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  background-color: rgb(236, 219, 214);
  padding: 10px;
  margin: 10px;
  border-radius: 8px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

  .user-info {
    display: flex;
    align-items: center;
    gap: 20px;
    cursor: pointer;
  }

  .follow-link {
    color: var(--accent-color);
  }
}

.Follower:hover{
  background-color: rgb(219, 187, 177);

}